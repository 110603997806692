import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mm-js/mm-js/applications/auth/src/app/[lang]/AuthSwitchWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mm-js/mm-js/applications/auth/src/app/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/home/runner/work/mm-js/mm-js/design/ragnar-ui/src/box/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Clickable"] */ "/home/runner/work/mm-js/mm-js/design/ragnar-ui/src/clickable/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mm-js/mm-js/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mm-js/mm-js/node_modules/next/dist/client/link.js");
